<template>
  <div class="col-12 col-md-12 col-lg-4 col-xl-6">
    <div
      v-if="isCandidateLoading"
      style="
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-25px"
          src="/media/loading_icon.gif"
          style="width: 35px"
        />
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-lg-row-fluid" v-else>
      <!--begin:::Tabs-->
      <ul
        class="
          nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
          border-0
          fs-4
          fw-semibold
          mb-8
        "
        role="tablist"
      >
        <!--begin:::Tab item-->
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-active-primary pb-4 active"
            data-bs-toggle="tab"
            href="#kt_customer_view_overview_tab"
            aria-selected="true"
            role="tab"
            >Aday Detayları</a
          >
        </li>
        <!--end:::Tab item-->

        <!--begin:::Tab item-->
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-active-primary pb-4"
            data-bs-toggle="tab"
            href="#kt_customer_view_overview_events_and_logs_tab"
            aria-selected="false"
            role="tab"
            tabindex="-1"
            >Mülakat Cevapları</a
          >
        </li>
        <!--end:::Tab item-->

        <!--begin:::Tab item-->
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-active-primary pb-4"
            data-kt-countup-tabs="true"
            data-bs-toggle="tab"
            href="#kt_customer_view_overview_statements"
            data-kt-initialized="1"
            aria-selected="false"
            role="tab"
            tabindex="-1"
            >Mülakat Logları</a
          >
        </li>
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->

      <!--begin:::Tab content-->
      <div class="tab-content" id="myTabContent">
        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade active show"
          id="kt_customer_view_overview_tab"
          role="tabpanel"
        >
          <!--begin::Contacts-->
          <div class="card card-flush h-lg-100" id="kt_contacts_main">
            <!--begin::Card header-->
            <div class="card-header pt-3" id="kt_chat_contacts_header">
              <!--begin::Card title-->
              <div class="card-title">
                <i class="ki-duotone ki-badge fs-1"
                  ><span class="path1"></span><span class="path2"></span
                  ><span class="path3"></span><span class="path4"></span
                  ><span class="path5"></span
                ></i>
                <h2>Aday Detayları</h2>
              </div>
              <div class="card-toolbar gap-3">
                <span
                  v-if="!candidate.candidateStatus"
                  class="badge badge-light-success"
                >
                  Aktif Aday
                </span>
                <div v-else>
                  <span
                    v-if="
                      candidate.candidateStatus ===
                      InterviewCandidateStatus.ACTIVE
                    "
                    class="badge badge-light-success"
                  >
                    Aktif Aday
                  </span>
                  <span
                    v-else-if="
                      candidate.candidateStatus ===
                      InterviewCandidateStatus.PASSIVE
                    "
                    class="badge badge-light-warning"
                  >
                    Pasif Aday
                  </span>
                  <span
                    v-else-if="
                      candidate.candidateStatus ===
                      InterviewCandidateStatus.ELIMINATED
                    "
                    class="badge badge-light-danger"
                  >
                    {{ $t("modals.interview.candidate.eliminated") }}
                  </span>
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body pt-5">
              <div
                class="d-flex justify-content-between align-items-center gap-7"
              >
                <!-- Sol taraftaki bilgiler -->
                <div class="d-flex align-items-center gap-7">
                  <router-link
                    :to="'/interviewer/candidate-detail/' + candidate.id"
                  >
                    <div
                      class="symbol symbol-100px symbol-circle mb-2"
                      v-if="candidate.candidateStatus"
                    >
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-1
                        "
                        v-if="candidate.firstName"
                      >
                        <div
                          class="symbol-label fs-1 pt-1"
                          :class="
                            InterviewCandidateStatusClass(
                              candidate.candidateStatus
                            )
                          "
                        >
                          {{ candidate.firstName.charAt(0) }}
                        </div>
                      </div>
                      <!--end::Avatar-->
                    </div>
                  </router-link>

                  <!--begin::Contact details-->
                  <div class="d-flex flex-column gap-2">
                    <!--begin::Name-->
                    <router-link
                      :to="'/interviewer/candidate-detail/' + candidate.id"
                    >
                      <h2 class="mb-0 text-hover-primary">
                        {{ candidate.fullName }}
                      </h2>
                    </router-link>
                    <!--end::Name-->

                    <!--begin::Email-->
                    <div class="d-flex">
                      <i class="ki-duotone ki-sms fs-2">
                        <span class="path1"></span><span class="path2"></span>
                      </i>
                      <a href="#" class="text-muted text-hover-primary">{{
                        candidate.email
                      }}</a>
                    </div>

                    <!--end::Email-->
                  </div>
                  <!--end::Contact details-->
                </div>

                <!-- Sağ taraftaki puanlama bilgileri -->
                <div class="d-flex gap-4">
                  <!--begin::Stats-->
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      py-3
                      px-3
                    "
                  >
                    <div
                      class="fs-4 fw-bold text-gray-700"
                      v-if="candidate.positionSuitability"
                    >
                      <span class="w-75px"
                        >{{ candidate.positionSuitability }}%</span
                      >
                    </div>
                    <div class="fs-4 fw-bold text-gray-700" v-else>
                      <span class="w-75px">
                        {{
                          $t("modals.interview.candidate.noPositionSuitability")
                        }}
                      </span>
                    </div>
                    <div class="fw-semibold text-muted">
                      {{ $t("modals.interview.candidate.positionEligibility") }}
                    </div>
                  </div>
                  <!--end::Stats-->

                  <!--begin::Stats-->
                  <div
                    class="
                      border border-gray-300 border-dashed
                      rounded
                      py-3
                      px-3
                    "
                  >
                    <div class="fs-4 fw-bold text-gray-700">
                      <div
                        v-if="
                          (candidate.CvAnalysisStatus == 'IN_PROGRESS' &&
                            isCvAnalysisLoading) ||
                          ((!candidate.CvAnalysisScore ||
                            candidate.CvAnalysisScore == null) &&
                            candidate.CvAnalysisStatus == 'IN_PROGRESS')
                        "
                        class="d-flex justify-content-center align-items-center"
                      >
                        <!-- Spinner'ı ortalamak için d-flex kullanıyoruz -->
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">{{
                            $t("common.loading")
                          }}</span>
                        </div>
                      </div>
                      <div v-else>
                        <span class="w-75px"
                          >{{ candidate.CvAnalysisScore }}%</span
                        >
                        <button
                          v-if="!candidate.CvAnalysisScore"
                          class="btn btn-icon h-25px btn-sm ms-2"
                          @click="reloadCVAnalysis()"
                        >
                          <span
                            class="
                              svg-icon svg-icon-sm svg-icon-1 svg-icon-primary
                            "
                            title="İş İlanına Göre CV Analiz Et"
                          >
                            <inline-svg
                              src="/media/icons/duotune/abstract/abs037.svg"
                            />
                          </span>
                        </button>
                        <i class="ki-duotone ki-arrow-up fs-3 text-success">
                          <span class="path1"></span><span class="path2"></span>
                        </i>
                      </div>
                    </div>
                    <div class="fw-semibold text-muted">CV Analizi</div>
                  </div>
                  <!--end::Stats-->
                </div>
              </div>

              <!--end::Profile-->

              <!-- <div class="d-flex flex-column gap-5 mt-7">
                <div class="d-flex flex-column gap-1">
                  <div class="fw-bold text-muted">Mezun Olduğu Bölüm</div>
                  <div class="fw-bold fs-6">Yazılım Mühendisliği</div>
                </div>
                <div class="d-flex flex-column gap-1">
                  <div class="fw-bold text-muted">Mezun Olduğu Okul</div>
                  <div class="fw-bold fs-6">Doğuş Üniversitesi</div>
                </div>
                <div class="d-flex flex-column gap-1">
                  <div class="fw-bold text-muted">İş Tecrübesi</div>
                  <div class="fw-bold fs-6">2 yıl</div>
                </div>
                <div class="d-flex flex-column gap-1">
                  <div class="fw-bold text-muted">Yabancı Dil</div>
                  <div class="fw-bold fs-6">İngilizce</div>
                </div>
                <div class="d-flex flex-column gap-1">
                  <div class="fw-bold text-muted">Adres</div>
                  <div class="fw-bold fs-6">
                    Cevatpaşa Mahallesi Çalışkan Sokak No 14 Daire 7, Istanbul,
                    Turkiye
                  </div>
                </div>
              </div> -->
              <!--end::Additional details-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Contacts-->
          <!--begin::Earnings-->
          <div class="card mb-4 mb-xl-9 mt-7">
            <!--begin::Header-->
            <div class="card-header border-0">
              <div class="card-title pt-7">
                <h2>Mülakat Analizi</h2>
              </div>
              <div class="fs-7 fw-semibold text-gray-500">
                Adayın mülakat deneyimi ile yapay zekanın analizi ve ilgili
                pozisyona uygunluk oranı
              </div>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body py-0 mt-5">
            
              <div
                v-if="candidate.interviews && candidate.interviews.length > 0"
                v-for="(interview, index) in candidate.interviews"
                :key="index"
                class="question-card"
              >
  
                <!-- Mülakat Başlığı -->
                <div class="question-title">
                  <div @click="goInterviewDetailPage(interview.id)" class="title-text text-hover-primary cursor-pointer">{{ interview.title }}</div>
                  <div
  class="badge"
  :class="{
    'bg-success': interview.interviewCandidateStatus === 'COMPLETED',
    'bg-warning': interview.interviewCandidateStatus === 'PENDING_ACTION',
    'bg-primary': interview.interviewCandidateStatus === 'IN_PROGRESS',
  }"
>
  <span class="fs-7" v-if="interview.interviewCandidateStatus === 'COMPLETED'">Başarı Oranı: </span>
  <span class="fs-6">
    {{
      interview.interviewCandidateStatus === 'COMPLETED'
        ? interview.interviewAnalysisScore + '%'
        : interview.interviewCandidateStatus === 'PENDING_ACTION'
        ? 'Davet Bekliyor'
        : interview.interviewCandidateStatus === 'IN_PROGRESS'
        ? 'Devam Ediyor'
        : ''
    }}
  </span>
</div>
                </div>

                <!-- Duygu Analizi - Tek Grafik -->
                <div
                  v-if="isCandidateInterviewChartsLoading == false && interview.emotionAnalysis"
                  class="emotion-analysis-wrapper"
                >
                  <apexchart
                    type="bar"
                    :options="getChartOptions()"
                    :series="[
                      { data: Object.values(interview.emotionAnalysis.data) },
                    ]"
                    height="300"
                  ></apexchart>
                </div>
                <div v-else-if="isCandidateInterviewChartsLoading" style="
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="text-center px-5 pb-7">
                    <img
                      alt="Logo"
                      class="max-h-25px"
                      src="/media/loading_icon.gif"
                      style="width: 35px"
                    />
                  </div>
                </div>
                <!-- <div v-else>
                  Duygu analizi bulunamadı.
                </div> -->
              </div>

              <div v-else>
                <p class="pt-6 pb-3 text-muted">
                  Bu iş ilanında mülakat bulunamadı.
                </p>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end::Earnings-->
        </div>
        <!--end:::Tab pane-->

        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade"
          id="kt_customer_view_overview_events_and_logs_tab"
          role="tabpanel"
        >
          <div class="card card-flush h-lg-100" id="kt_contacts_main">
            <!--begin::Card header-->
            <div class="card-header" id="kt_chat_contacts_header">
              <!--begin::Card title-->
              <div class="card-title pt-3">
                <i class="ki-duotone ki-badge fs-1"
                  ><span class="path1"></span><span class="path2"></span
                  ><span class="path3"></span><span class="path4"></span
                  ><span class="path5"></span
                ></i>
                <h2>Mülakat Cevapları</h2>
              </div>
              <!--begin::Controls-->
              <div class="d-flex flex-wrap mt-6">
                <!--begin::Select wrapper-->
                <div class="m-0">
                  <!--begin::Select-->
                  <select
                    name="status"
                    data-control="select2"
                    data-hide-search="true"
                    data-placeholder="Seçiniz..."
                    class="
                      form-select form-select-white form-select-sm
                      fw-bolder
                      w-175px
                    "
                    v-model="selectedInterviewId"
                  >
                    <option value="0" selected>Seçiniz...</option>
                    <option
                      v-for="(interview, index) in candidate.interviews"
                      :key="index"
                      :value="interview.id"
                    >
                      {{ interview.title }}
                    </option>
                  </select>
                  <!--end::Select-->
                </div>
                <!--end::Select wrapper-->
              </div>
              <!--end::Controls-->
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div
              class="card-body pt-5 mb-7"
              style="max-height: 700px; overflow: auto"
            >
              <div v-if="selectedInterviewId == 0">Lütfen mülakat seçiniz.</div>
              <div v-else>
                <div
                  v-if="isCandidateInterviewDetailLoading"
                  style="
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="text-center px-5 pb-7">
                    <img
                      alt="Logo"
                      class="max-h-25px"
                      src="/media/loading_icon.gif"
                      style="width: 35px"
                    />
                  </div>
                </div>

                <div
                  class="d-flex flex-column gap-5"
                  v-else-if="
                    candidateInterviewResponse.interviewQuestions &&
                    candidateInterviewResponse.interviewQuestions.length > 0
                  "
                >
                  <!--begin::Question 1-->
                  <div
                    class="question-card"
                    v-for="(
                      question, index
                    ) in candidateInterviewResponse.interviewQuestions"
                    :key="index"
                  >
                    <div class="question-title">
                      <div class="title-text">
                        {{ question.questionText }}
                      </div>
                      <span
                        class="badge"
                        :class="{
                          'bg-primary': question.questionType === 'OPEN_ENDED',
                          'bg-success':
                            question.questionType === 'SINGLE_CHOICE',
                          'bg-danger':
                            question.questionType === 'MULTIPLE_CHOICE',
                        }"
                      >
                        {{
                          question.questionType === "OPEN_ENDED"
                            ? "Açık Uçlu"
                            : question.questionType === "SINGLE_CHOICE"
                            ? "Tek Seçenekli"
                            : "Çok Seçenekli"
                        }}
                      </span>
                    </div>

                    <div class="question-answer">
                      <div v-if="question.interviewQuestionResponse">
                        {{ question.interviewQuestionResponse }}
                      </div>
                      <div v-else class="no-answer">
                        <i class="fa fa-exclamation-circle"></i> Cevap
                        verilmedi.
                      </div>
                    </div>
                  </div>
                  <!--end::Question 1-->
                </div>
                <div v-else>Bu mülakatta soru bulunamadı.</div>
              </div>
            </div>

            <!--end::Card body-->
          </div>
        </div>
        <!--end:::Tab pane-->

        <!--begin:::Tab pane-->
        <div
          class="tab-pane fade"
          id="kt_customer_view_overview_statements"
          role="tabpanel"
        >
          <!--begin::Statements-->
          <div class="card mb-6 mb-xl-9">
            <!--begin::Header-->
            <div class="card-header">
              <!--begin::Title-->
              <div class="card-title pt-3">
                <h2>Mülakat Logları</h2>
              </div>
              <!--end::Title-->
              <!--begin::Controls-->
              <div class="d-flex flex-wrap mt-6">
                <!--begin::Select wrapper-->
                <div class="m-0">
                  <!--begin::Select-->
                  <select
                    name="status"
                    data-control="select2"
                    data-hide-search="true"
                    class="
                      form-select form-select-white form-select-sm
                      fw-bolder
                      w-175px
                    "
                    v-model="selectedInterviewId"
                  >
                    <option value="0" selected>Seçiniz...</option>
                    <option
                      v-for="(interview, index) in candidate.interviews"
                      :key="index"
                      :value="interview.id"
                    >
                      {{ interview.title }}
                    </option>
                  </select>
                  <!--end::Select-->
                </div>
                <!--end::Select wrapper-->
              </div>
              <!--end::Controls-->
            </div>
            <!--end::Header-->

            <!--begin::Card body-->
            <div
              class="card-body pb-5 mb-7"
              style="max-height: 700px; overflow: auto"
            >
              <div v-if="selectedInterviewId == 0">Lütfen mülakat seçiniz.</div>
              <div v-else>
                <div
                  v-if="isCandidateInterviewDetailLoading"
                  style="
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="text-center px-5 pb-7">
                    <img
                      alt="Logo"
                      class="max-h-25px"
                      src="/media/loading_icon.gif"
                      style="width: 35px"
                    />
                  </div>
                </div>
                <div
                  class="row mt-5"
                  v-else-if="candidateInterviewLogs.length > 0"
                  v-for="(message, index) in candidateInterviewLogs"
                  :key="index"
                >
                  <!-- burası -->
                  <!-- Soru 1 -->
                  <MessageIn
                    v-if="message.message.owner == 'AI'"
                    ref="messagesInRef"
                    :text="message.message.text"
                    :id="message.message.messageId"
                    :voice="message.message.voice"
                  ></MessageIn>
                  <MessageOut
                    v-else
                    ref="messagesOutRef"
                    :text="message.message.text"
                    :voice="message.message.voice"
                    :id="message.message.messageId"
                  ></MessageOut>
                </div>
                <div v-else>Bu mülakat için herhangi bir kayıt bulunamadı.</div>
              </div>
            </div>

            <!-- <div
              class="card-body pb-5 mb-7"
              style="max-height: 700px; overflow: auto"
            >
              <div
                class="row mt-5"
                v-for="(log, index) in pollChatLogs"
                :key="index"
              >
                <MessageIn
                  v-if="log.poll.owner == 'AI'"
                  ref="messagesInRef"
                  :text="log.poll.text"
                  :id="log.poll.id"
                  :voice="log.poll.voice"
                ></MessageIn>
                <MessageOut
                  v-else
                  ref="messagesOutRef"
                  :text="log.poll.text"
                  :voice="log.poll.voice"
                  :video="log.poll.video"
                  :id="log.poll.id"
                ></MessageOut>
              </div>
            </div> -->
            <!--end::Card body-->
          </div>
          <!--end::Statements-->
        </div>
        <!--end:::Tab pane-->
      </div>
      <!--end:::Tab content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";
import { InterviewCountModel } from "@/domain/interviewer/interview-counts/model/InterviewCountModel";
import { useStore } from "vuex";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { CandidateDetailModel } from "@/domain/interviewer/candidate-detail/model/CandidateDetailModel";
import { CandidateInterviewModel } from "@/domain/interviewer/candidate-detail/model/CandidateInterviewModel";
import { InterviewCandidateStatus } from "@/domain/constants/InterviewCandidateStatus";
import Swal from "sweetalert2";
import { InterviewCandidateStatusClass } from "@/presentation/helper/StatusBadge";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";
import { CandidateCvAnalysisStatus } from "@/domain/constants/CandidateCvAnalysisStatus";
import { CandidateInterviewResponsesModel } from "@/domain/interviewer/candidate-interview-responses/model/CandidateInterviewResponsesModel";
import { CandidateInterviewLogsModel } from "@/domain/interviewer/candidate-interview-logs/model/CandidateInterviewLogsModel";

export default defineComponent({
  name: "jobposting-detail-candidate",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  methods: {
    getEmotionLabel(emotion) {
      const labels = {
        happy: "Mutlu",
        sad: "Üzgün",
        angry: "Öfkeli",
        fear: "Korku",
        disgust: "Tiksinti",
        surprise: "Şaşkın",
        neutral: "Nötr",
      };
      return labels[emotion] || emotion;
    },
  },
  computed: {},
  setup() {
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");
    const store = useStore();
    const swalNotification = new SwalNotification();
    const route = useRoute();
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const isCvAnalysisLoading = ref<boolean>(false);
    const isCandidateLoading = ref<boolean>(false);
    const isCandidateInterviewDetailLoading = ref<boolean>(false);
      const isCandidateInterviewChartsLoading = ref<boolean>(false);

    const features = ref<string[]>([]);
    const router = useRouter();

    const chartKey = ref<number>(0);

    const selectedInterviewId = ref<number>(0);

    const candidate = ref<CandidateDetailModel>({});

    const jobPostingId = ref<any>(Number(route.params["jobpostingId"]));
    const candidateId = ref<any>(Number(route.params["candidateId"]));
    const jobPosting = ref<JobPostingDetailModel>({});
    const jobPostingInterviewCount = ref<InterviewCountModel>({});

    const candidateInterviewList = ref<CandidateInterviewModel[]>([]);
    const candidateInterviewResponse = ref<CandidateInterviewResponsesModel>({
      interviewQuestions: [],
    });

    const candidateInterviewLogs = ref<CandidateInterviewLogsModel[]>([]);

    const interviewerController =
      store.state.ControllersModule.interviewerController;

    let checkCvAnalysisInterval;

    const startInterval = () => {
      checkCvAnalysisInterval = setInterval(() => {
        if (
          candidateId.value === candidate.value.id &&
          candidate.value &&
          candidate.value.CvAnalysisScore == null &&
          candidate.value.CvAnalysisStatus ==
            CandidateCvAnalysisStatus.IN_PROGRESS
        ) {
          isCvAnalysisLoading.value = true;
          const candidateDetailModel: CandidateDetailModel = {
            id: candidateId.value,
          };

          interviewerController
            .candidateDetail(candidateDetailModel)
            .then((response) => {
              if (response.isSuccess && response.getValue().CvAnalysisScore) {
                isCvAnalysisLoading.value = false;
                clearInterval(checkCvAnalysisInterval);
                getCandidate();
              }
            })
            .catch((e) => {
              isCvAnalysisLoading.value = false;
              clearInterval(checkCvAnalysisInterval);
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        } else if (
          (candidate.value.CvAnalysisScore == undefined &&
            candidate.value.CvAnalysisStatus == undefined) ||
          (candidate.value.CvAnalysisScore &&
            candidate.value.CvAnalysisStatus !=
              CandidateCvAnalysisStatus.IN_PROGRESS)
        ) {
          clearInterval(checkCvAnalysisInterval);
        }
      }, 2000);
    };

    const getJobPosting = () => {
      isLoading.value = false;

      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue());
            if (jobPosting.value.startDate && jobPosting.value.endDate) {
              startDate.value = new Date(jobPosting.value.startDate)
                .toISOString()
                .slice(0, 10);
              endDate.value = new Date(jobPosting.value.endDate)
                .toISOString()
                .slice(0, 10);
            }
          } else {
            router.push({ name: "500" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateInterviewResponse = () => {
      isCandidateInterviewChartsLoading.value = true;

      candidateInterviewResponse.value = {
        interviewQuestions: [],
      };

      if (selectedInterviewId.value == 0) {
        return;
      }

      const candidateInterviewResponsesModel: CandidateInterviewResponsesModel =
        {
          interviewQuestions: [],
          interview: selectedInterviewId.value,
          candidate: candidateId.value,
        };

      interviewerController
        .candidateInterviewResponses(candidateInterviewResponsesModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(
              candidateInterviewResponse.value,
              response.getValue()
            );
          } else {
            candidateInterviewResponse.value = { interviewQuestions: [] };
            // router.push({ path: "/interviewer/jobposting-list" });
            // swalNotification.error(
            //   response.error.cause.cause +
            //     t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
            //     response.error.code +
            //     response.error.cause.code,
            //   t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            // );
          }
          isCandidateInterviewChartsLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getCandidateInterviewLogs = () => {
      isCandidateInterviewChartsLoading.value = true;
      candidateInterviewLogs.value = [];

      if (selectedInterviewId.value == 0) {
        return;
      }

      // DÜZENLENECEK

      let candidateInterviewLogModel: CandidateInterviewLogsModel = {
        interview: selectedInterviewId.value,
        candidate: candidateId.value,
        message: {},
      };

      interviewerController
        .candidateInterviewLogs(candidateInterviewLogModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(candidateInterviewLogs.value, response.getValue());
          } else {
            candidateInterviewLogs.value = [];
          }
          isCandidateInterviewDetailLoading.value = false;
          isCandidateInterviewChartsLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
          isCandidateInterviewDetailLoading.value = false;
          isCandidateInterviewChartsLoading.value = false;
        });
    };

    const getCandidate = () => {
      isCandidateInterviewChartsLoading.value = true;
      isCandidateLoading.value = false;

      const candidateDetailModel: CandidateDetailModel = {
        id: candidateId.value,
      };

      isCandidateLoading.value = true;

      interviewerController
        .candidateDetail(candidateDetailModel)
        .then((response) => {
          if (response.isSuccess) {

            isCandidateInterviewChartsLoading.value = false;
            Object.assign(candidate.value, response.getValue());
            console.log("candidate.value", candidate.value)
            if (candidate.value.jobPostingId != jobPostingId.value) {
              router.push({ name: "500" });
            }
            Object.assign(
              candidateInterviewList.value,
              response.getValue().interviews
            );

            isCandidateLoading.value = false;
            isCandidateInterviewDetailLoading.value = true;

            getCandidateInterviewResponse();
            getCandidateInterviewLogs();
          } else {
            router.push({ path: "/interviewer/jobposting-list" });
            showError(response.error);
          }
          isCandidateInterviewChartsLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const showError = (error) => {
      const errorMessage = `${error.cause?.cause || ""} ${t(
        SWAL_MESSAGES.ERROR.ERROR_CODE
      )} ${error.code || ""} ${error.cause?.code || ""}`;
      swalNotification.error(
        errorMessage,
        t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
      );
    };

    const updateCandidateDetails = async () => {
      try {
        const candidateDetailModel = { id: candidateId.value };
        const response = await interviewerController.candidateDetail(
          candidateDetailModel
        );

        if (response.isSuccess) {
          Object.assign(candidate.value, response.getValue());
          Object.assign(
            candidateInterviewList.value,
            response.getValue().interviews
          );
        } else {
          router.push({ path: "/interviewer/jobposting-list" });
          showError(response.error);
        }
      } catch (error) {
        showError(error);
      }
    };

    const pollCvAnalysisStatus = () => {
      const intervalId = setInterval(async () => {
        if (!candidate.value) return clearInterval(intervalId);

        if (
          candidateId.value === candidate.value.id &&
          candidate.value.CvAnalysisScore == null &&
          candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.IN_PROGRESS &&
          candidateId.value == candidate.value.id
        ) {
          try {
            const candidateDetailModel = { id: candidateId.value };
            const response = await interviewerController.candidateDetail(
              candidateDetailModel
            );

            if (response.isSuccess && response.getValue().CvAnalysisScore) {
              isCvAnalysisLoading.value = false;
              clearInterval(intervalId);
              Object.assign(candidate.value, response.getValue());
              Object.assign(
                candidateInterviewList.value,
                response.getValue().interviews
              );
            }
          } catch (error) {
            isCvAnalysisLoading.value = false;
            clearInterval(intervalId);
            showError(error);
          }
        } else if (
          candidate.value.CvAnalysisScore !== undefined &&
          (candidate.value.CvAnalysisStatus ===
            CandidateCvAnalysisStatus.COMPLETED ||
            candidate.value.CvAnalysisStatus ===
              CandidateCvAnalysisStatus.FAILED)
        ) {
          clearInterval(intervalId);
        }
      }, 2000);
    };

    const reloadCVAnalysis = async () => {
      const candidateCvAnalysisModel = { candidate: candidateId.value };

      const swalText = jobPosting.value.hasCvAnalysis
        ? t("swalMessages.CANNOT_BE_RIGHT_USAGE")
        : t("swalMessages.CANNOT_BE_UPDATE_JOBPOSTING");

      const confirmation = await Swal.fire({
        title: t("swalMessages.CANDIDATE_CV_ANALYSIS"),
        text: swalText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      });

      if (!confirmation.isConfirmed) return;

      try {
        const response = await interviewerController.candidateCvAnalysis(
          candidateCvAnalysisModel
        );

        if (!response.isSuccess) {
          return showError(response.error);
        }

        isCvAnalysisLoading.value = true;

        // await Swal.fire({
        //   title: t("swalMessages.SUCCESSFUL"),
        //   text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_CV_ANALYSIS),
        //   icon: "success",
        //   heightAuto: false,
        // });

        await updateCandidateDetails();
        pollCvAnalysisStatus();
      } catch (error) {
        showError(error);
      }
    };

    watch(
      () => route.params["candidateId"],
      () => {
        if (route.params["candidateId"]) {
          candidateId.value = Number(route.params["candidateId"]);
          getCandidate();
        }
      }
    );

    const getChartOptions = () => {
      const colorMap = {
        angry: "#FF4C4C", // Kızgın: kırmızı
        disgust: "#9C27B0", // Tiksinme: mor
        fear: "#FF9800", // Korku: turuncu
        happy: "#4CAF50", // Mutluluk: yeşil
        sad: "#2196F3", // Üzüntü: mavi
        surprise: "#FFC107", // Şaşkınlık: sarı
        neutral: "#9E9E9E", // Nötr: gri
      };

      return reactive({
        chart: {
          type: "bar", // Sütun grafik türü
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top", // Veri etiketlerini üstte göstereceğiz
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            colors: ["#333"],
          },
        },
        colors: Object.keys(colorMap).map((key) => colorMap[key]), // Her duyguyu renkleriyle ekliyoruz
        xaxis: {
          categories: [
            "Kızgın",
            "Tiksinme",
            "Korku",
            "Mutluluk",
            "Üzüntü",
            "Şaşkınlık",
            "Nötr",
          ], // Duyguları kategoriler olarak kullanıyoruz
        },
        yaxis: {
          title: {
            text: "Puan",
          },
        },
      });
    };

const goInterviewDetailPage = (interviewId?: number) => {
      if (interviewId) {
        router.push({ path: `/interviewer/interview-detail/` + interviewId });
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPosting();
      getCandidate();
      startInterval();
    });

    watch(
      () => candidate.value.id,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          clearInterval(checkCvAnalysisInterval);
          startInterval();
        }
      }
    );

    watch(
      () => selectedInterviewId.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          isCandidateInterviewDetailLoading.value = true;
          getCandidateInterviewResponse();
          getCandidateInterviewLogs();
        }
      }
    );

    return {
      InterviewCandidateStatus,
      isLoading,
      jobPostingId,
      features,
      today,
      startDate,
      endDate,
      jobPosting,
      jobPostingInterviewCount,
      JobPostingStatus,
      InterviewCandidateStatusClass,
      candidate,
      isCandidateLoading,
      isCvAnalysisLoading,
      reloadCVAnalysis,
      selectedInterviewId,
      getCandidateInterviewResponse,
      candidateInterviewResponse,
      candidateInterviewLogs,
      isCandidateInterviewDetailLoading,
      getChartOptions,
      isCandidateInterviewChartsLoading,
      goInterviewDetailPage,
    };
  },
});
</script>

<style scoped>
/* Genel kart stili */
.question-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.3s ease;
}

.question-card:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

/* Başlık stil */
.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Başlık metni */
.title-text {
  margin-right: 10px;
}

/* Mülakat Başarısı Analizi */
.badge {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 12px;
  text-align: center;
}

.bg-success {
  background-color: #28a745;
}

.bg-danger {
  background-color: #dc3545;
}

/* Duygu Analizi Çerçevesi */
.emotion-analysis-wrapper {
  margin-top: 15px;
}

.apexcharts-canvas {
  margin-top: 20px; /* Grafiklerin üst kısmındaki boşluğu artırıyoruz */
}
</style>
